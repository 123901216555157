import React from 'react'
import g from 'glamorous'
import CommercialImage from '../components/CommercialImage'
import ResidentialImage from '../components/ResidentialImage'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

const ClientPage = () => (
  <Layout>
    <Helmet>
      <title>Clients | Valerie Pasquiou Interiors & Design</title>
      <meta
        name="description"
        content="Our clients for commercial and residential projects. Complete list upon request."
      />
    </Helmet>
    <Wrapper>
      <H1>Clients</H1>
      <Commercial>
        <Col>
          <H2>Commercial</H2>
          <CommercialImage />
        </Col>
        <Col>
          <Ul>
            <Li>Barbara Bui, NY</Li>
            <Li>Beautique Restaurant, NY</Li>
            <Li>Botkier Boutique, NY</Li>
            <Li>Box Park Restaurant, Miami</Li>
            <Li>Cartier, NY</Li>
            <Li>Dreamdry, NY</Li>
            <Li>Kate Somerville, Los Angeles</Li>
            <Li>Edelman Leather Showroom, NY</Li>
            <Li>Fashion Tech Forum, NY</Li>
            <Li>Final Cut - edit, NY</Li>
            <Li>Frederic Fekkai, Soho NY</Li>
          </Ul>
        </Col>
        <Col>
          <Ul>
            <Li>Hermes, NY</Li>
            <Li>International Rescue Committee Headquarters, NY</Li>
            <Li>Karen Harvey Consulting, London & NY</Li>
            <Li>Kinara Spa & Hair Salon, LA</Li>
            <Li>L'Oréal, NY</Li>
            <Li>Maison Epigenetic, Paris</Li>
            <Li>MTV Networks, NYC</Li>
            <Li>Private College St Charles, Switzerland</Li>
            <Li>Ralph Lauren, NY</Li>
            <Li>Swarovski, NY</Li>
            <Li>Véronique Gabai Parfums, Paris</Li>
          </Ul>
        </Col>
      </Commercial>
    </Wrapper>
  </Layout>
)

export default ClientPage

const Wrapper = g.div({})

const Commercial = g.section({
  marginTop: `100px`,
  display: `grid`,
  gridTemplateColumns: `32% 34.3% 34.3%`,
  gridGap: `2em`,
  '@media(max-width: 768px)': {
    display: `block`,
    marginTop: `10px`,
  },
})

const Residential = g.section({
  display: `grid`,
  gridTemplateColumns: `32% 34.3% 34.3%`,
  gridGap: `2em`,
  '@media(max-width: 768px)': {
    display: `block`,
    marginTop: `10px`,
  },
})

const Col = g.div({})

const H1 = g.h1({
  '@media(min-width: 768px)': {
    display: `none !important`,
  },
  display: `block`,
  marginBottom: `.2em`,
  marginLeft: `0`,
  marginTop: `5px`,
  padding: `0 15px`,
  textAlign: `center`,
  fontSize: `2.2em`,
})

const Hr = g.div({
  borderBottom: `1px solid rgba(31,31,31,.2)`,
  margin: `1.4em 0 1em`,
  '@media(max-width: 768px)': {
    margin: `0`,
    display: `none`,
  },
})

const H2 = g.h2({
  position: `absolute`,
  padding: `.5em .75em`,
  zIndex: 2,
  color: `rgba(255,255,255,1)`,
  fontWeight: `bold`,
  fontVariant: `small-caps`,
  fontSize: `30px`,
  letterSpacing: `.1em`,
  '@media(max-width: 768px)': {
    position: `relative`,
    padding: `.75em 15px 0`,
    fontSize: `1.5em`,
    color: `rgba(0,0,0,1)`,
    textAlign: `left`,
    zIndex: 0,
  },
})

const Ul = g.ul({
  listStyle: `none`,
  padding: 0,
  '@media(max-width: 768px)': {
    padding: `0 18px`,
    margin: `0`,
  },
})

const Li = g.li({
  marginBottom: `4px`,
  '@media(max-width: 768px)': {
    fontSize: `.95em`,
    letterSpacing: `-.01em`,
  },
})
